import request from '@/utils/request'
import storage from 'store'
import { LOCATION } from '@/store/mutation-types'

// 列表
export function getCoupons(params) {
  return request({
    url: '/api/client/user-coupons/mine',
    method: 'get',
    params,
  })
}

// 详情
export function getCouponDetail(id) {
  const { latitude, longitude } = storage.get(LOCATION) || {}
  return request({
    url: `/api/client/user-coupons/${id}`,
    method: 'get',
    params: {
      latitude,
      longitude,
    },
  })
}

// 兑换
export function couponExchange(data) {
  return request({
    url: `/api/client/user-coupons/exchange`,
    method: 'post',
    data,
  })
}

// 查询充值状态
export function queryRechargeStatus(params) {
  return request({
    url: `/api/client/user-coupons/query-recharge-status`,
    method: 'get',
    params,
  })
}

export function selectMemberCoupons(params) {
  return request({
    url: `/api/client/member/available-coupons`,
    method: 'get',
    params
  })
}

/**
 * 读取是否可以激活兑换券
 * @param {*} params
 * @returns
 */
export function checkExchange(params) {
  return request({
    url: `/api/client/user-coupons/exchange-check`,
    method: 'get',
    params
  })
}

/**
 * 兑换v2
 * @param {*} data 
 * @returns 
 */
export function couponExchangeV2(data) {
  return request({
    url: `/api/client/user-orders/create`,
    method: 'post',
    data
  })
}

export function orderCancel (id) {
  return request({
    url: `/api/client/user-orders/${id}/cancel`,
    method: 'GET'
  })
}

/**
 * 删除订单
 * @param {*} id 
 * @returns 
 */
export function removeOrder (id) {
  return request({
    url: `/api/client/user-orders/${id}`,
    method: 'delete'
  })
}

export function checkSpecialTopicActivity (id) {
  return request({
    url: `/api/client/activity/check-activity/${id}`,
    method: 'GET'
  })
}

export function getActivityName () {
  return request({
    url: `/api/client/notices/label`,
    method: 'GET'
  })
}